import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { CommonLookupServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css'],
  animations: [accountModuleAnimation()]
})
export class MaintenanceComponent implements OnInit {
  originalUrl: string;
  constructor(private _commonLookupServiceProxy: CommonLookupServiceProxy) {
    this.loadScripts();
  }

  ngOnInit() {
    const params = new URLSearchParams(window.location.search);
    this.originalUrl = params.get('originalUrl');
    this.checkApiStatus();
    setInterval(() => {
      this.checkApiStatus();
    }, 60000);
  }

  loadScripts() {
    const dynamicCss = [
      'assets/anonymouspage/css/style.css',
    ];
    for (let i = 0; i < dynamicCss.length; i++) {
      const node = document.createElement('link');
      node.href = dynamicCss[i];
      node.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  checkApiStatus() {
    this._commonLookupServiceProxy.ping().subscribe((response) => {
      if (response && response.isSuccess) {
        const redirectUrl = this.originalUrl || "/";
            const separator = redirectUrl.includes('?') ? '&' : '?'; // Check if query parameters already exist
            window.location.href = redirectUrl + separator + "ts=" + new Date().getTime();
      }
    });
  }
}
