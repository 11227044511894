import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { Component, Injector, OnInit, ViewChild, ViewEncapsulation, InjectionToken, Optional, Inject, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, Input, HostListener } from '@angular/core';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { EditionPaymentType, SubscriptionStartType } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { ChangeUserLanguageDto, GetCurrentLoginInformationsOutput, LinkedUserDto, ProfileServiceProxy, SessionServiceProxy, TenantLoginInfoDto, UserLinkServiceProxy, UserServiceProxy, SettingServiceProxy, CalculationServiceProxy, ProjectInput, CalculationInput, UserLoginInfoDto, AccountServiceProxy, SubscriptionServiceProxy, CommonLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LinkedAccountsModalComponent } from './linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from './login-attempts-modal.component';
import { ChangePasswordModalComponent } from './profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from './profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from './profile/my-settings-modal.component';
import { Router, NavigationStart, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { EventEmitterService } from '@shared/service-proxies/EventEmitterService';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { FileUploadSendEmailService, FileUploadSendEmail } from '@app/shared/common/fileupload/file-upload.service';
import * as html2canvas from 'html2canvas';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxCaptureService } from 'ngx-capture';
import { filter, finalize, tap } from 'rxjs/operators';
import { CalculationService, PlanService, ProductService, SettingService } from 'services/project.service';
import { NetworkCalcComponent } from '@app/main/cablepro/Network/networkcalc.component';
import { LoaderService } from '@app/main/cablepro/loader.service';
import { ConfirmationModalComponent } from '../common/confirmation-modal/confirmation-modal.component';
import { CompanyAccountService } from '@shared/service-proxies/company-account.service';
import { Currency, getAllISOCodes } from 'iso-country-currency';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MomentHelper } from '@shared/helpers/MomentHelper';
@Component({
    templateUrl: './header.component.html',
    selector: 'header-bar',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./header.component.css']

})
export class HeaderComponent extends AppComponentBase implements OnInit {
    @ViewChild('notificationSettingsModal', { static: true }) notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('loginAttemptsModal', { static: true }) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal', { static: true }) linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', { static: true }) mySettingsModal: MySettingsModalComponent;
    @ViewChild('attachments', { static: true }) attachment: any;
    @ViewChild('lblfilecontact', { static: false }) string: any;
    @ViewChild('screen', { static: true }) screen: ElementRef;
    @ViewChild('canvas', { static: true }) canvas: ElementRef;
    @ViewChild('downloadLink', { static: false }) downloadLink: ElementRef;
    @ViewChild(NetworkCalcComponent) networkcal: NetworkCalcComponent;
    @ViewChild('calculationPopover') myPopover: any;
    @ViewChild('projectPopover') myProjectPopover: any;
    // @ViewChild('screen', { static: true }) screen: any;
    languages: abp.localization.ILanguageInfo[];
    file: FileUploadSendEmail;
    selectedFile: File = null;
    imageFile: File = null;
    projectnm: any = '';
    cname: any = '';
    public user!: UserLoginInfoDto | undefined;
    IsActiveBugLocation: boolean = false;
    IsActive: boolean = false;
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    shownLoginNameTitle = '';
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.ui.getAsideSkin() + '.png';
    recentlyLinkedUsers: LinkedUserDto[];
    unreadChatMessageCount = 0;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    chatConnected = false;
    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();
    contactForm: FormGroup;
    reportbugForm: FormGroup;
    subscriptionStartType = SubscriptionStartType;
    editionPaymentType: typeof EditionPaymentType = EditionPaymentType;
    public _router: Router;
    public modaltitle: any;
    // public showSpinner: boolean = false;
    public bodyreportabug: any;
    public bodycontactus: any;
    public projectInput: ProjectInput = new ProjectInput();

    blobFile: any;
    //public formData:any={};
    public IsActiveReportBugScreenShot: boolean = true;
    public IsActiveReportBugEmailCopy: boolean = false;
    public IsActiveReportSendCopy: boolean = false;
    public IsActiveContactSendCopy: boolean = false;
    public IsActiveContactUsScreenShot: boolean = true;
    //public file:any=[''];
    public hasFile: boolean = false;
    public emailreportabug: any = this._appSessionService.user.emailAddress;
    public emailcontactus: any = this._appSessionService.user.emailAddress;
    public subjectreportbug: any = "Cable Pro Web Bug Report";
    public subjectcontact: any = "Cable Pro Web Technical Support";

    public premiumUser: boolean;
    public locationswitch: boolean = true;
    private baseUrl: string;
    private productLiftSsoToken: string;
    public unRegisteredUser: boolean = false;
    public feedbackUrl: any;
    public IsUserHasProfessionalPlan: boolean;
    public isBCStandard: boolean = false;
    public isPlanActive: boolean = false;
    public userStripePlanName: string = '';
    public userStripeSubscriptionStatus: string = '';
    public expirationDate: string = '';
    public isAdminUser: boolean = false;
    public isProPlanUser: boolean = false;
    public isPlanAddedManually: boolean = false;
    public isNonStripeUser: boolean = false;
    private hasPlanExpired: boolean = false;
    public expiredPlanHeadingText: string = '';
    public expiredPlanBodyText: string = '';
    private planDueDays: number = 0;
    public planLevel: string | number = '';
    public ProjectId: number = 0;
    public CalculationId: number = 0;
    public CalculationName: string = '';
    public isCalculationPopoverVisible: boolean = false;

    public Countries: Currency[] = getAllISOCodes();
    public countryCurrency: string = '$';

    get ProjectIdPristine(): number {
        let params = this.collectRouteParams(this.route);

        if (params && params["pid"]) {
            return parseInt(params["pid"]);
        }
        // Using snapshot to get the route parameter value
        return 0;
    }

    get CalculationNamePristine(): string {
        // Using snapshot to get the route parameter value
        return this.route.snapshot.queryParamMap.get('cname');
    }
    set CalculationNamePristine(value: string) {
        this.CalculationName = value;
    }

    get projectNamePristine(): string {
        // Using snapshot to get the route parameter value
        return this.route.snapshot.queryParamMap.get('name');
    }
    set projectNamePristine(value: string) {
        // Using snapshot to get the route parameter value
        this.projectName = value;
    }

    public projectName: string = "";
    @Input() calculationInput: CalculationInput = new CalculationInput();

    public projectOutput: any = [];
    public listCalcComponentObj: any;
    public ishidebutton: number = 0;
    public messagestr: string = '';
    public imgBase64: any;
    public emailreqmsg: string = "";
    public subjectreqmsg: string = "";
    public contactemailreq: string = "";
    public contactsubreq: string;
    public btnDisabled: boolean = false;
    buttonText: string = '';
    public projects = [
        { name: 'Misc.calculations' },
        { name: 'Project' },
        { name: 'Project(1)' },
        { name: 'Project(2)' },
        { name: 'Project(3)' },
    ];

    public popoverform = new FormGroup({
        // projectName: new FormControl(this.projectName),
        // calculationName: new FormControl(this.calculationName),
    });
    @ViewChild('confirmationModal')
    modalComponent!: ConfirmationModalComponent;
    public calculationStandard: number = 0;
    public planExtendedCount: number = 0;
    public isLoaderForExtendPlan = false;
    public standardAccessModalVisible: boolean = false;
    public standardAccessIframeLoader: boolean = false;
    public standardAccessUrl: SafeResourceUrl;
    public standardAccessloader: boolean = false;
    public standardAccessApiloader: boolean = false;

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder,
        private _calculationService: CalculationService,
        private _abpSessionService: AbpSessionService,
        private _planService: PlanService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        private _commonLookupServiceProxy: CommonLookupServiceProxy,
        private _authService: AppAuthService,
        private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService,
        private _userNotificationHelper: UserNotificationHelper,
        private _sessionService: SessionServiceProxy,
        private _appSessionService: AppSessionService,
        private router: Router,
        private _settingServiceProxy: SettingServiceProxy,
        private _settingService: SettingService,
        private _companyAccountService: CompanyAccountService,
        private _eventEmitterService: EventEmitterService,
        private _fileUploadService: FileUploadSendEmailService,
        private route: ActivatedRoute,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private captureService: NgxCaptureService
        , private _projectService: ProductService,
        private loaderService: LoaderService,
        private _subscriptionService: SubscriptionServiceProxy,
        private http: HttpClient
    ) {
        super(injector);
        this._router = router;
        this.premiumUser = true;
        this.isPlanAddedManually = true;
        this.isBCStandard = false;
        (window as any).header = this;
        this.standardAccessUrl = this.getStandardsRequestUrl();
    }

    public onPopoverSubmit() {
    }
    public onPopoverShown() {
        this.CalculationNamePristine = this.route.snapshot.queryParamMap.get('cname');
        this.CalculationName = this.route.snapshot.queryParamMap.get('cname');
        this.projectNamePristine = this.route.snapshot.queryParamMap.get('name');
        this.projectName = this.route.snapshot.queryParamMap.get('name');
        let params = this.collectRouteParams(this.route);
        this.isCalculationPopoverVisible = true;
        this.calculationInput = new CalculationInput();
        if (params && params["pid"]) {
            this.ProjectId = parseInt(params["pid"]);
        }

        if (params && params["cid"]) {
            this.CalculationId = parseInt(params["cid"]);
        }
    }


    get multiTenancySideIsTenant(): boolean {
        return this._abpSessionService.tenantId > 0;
    }
    capture() {
        let element = document.querySelector("#capture");
        let imgstring: any = '';
        let me = this;
        (html2canvas as any)(document.body).then(function (canvas) {
            // Convert the canvas to blob

            canvas.toBlob(function (blob) {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function () {

                    //me.modelvalue = reader.result;
                    imgstring = reader.result;
                    me.imgBase64 = imgstring;
                    me.reportbugForm.get("hdnimagestr").setValue(imgstring);

                };
                reader.onerror = function (error) {
                };

            })

        });

        this.imgBase64 = imgstring;
    }
    getBase64(event) {
        let me = this;
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //me.modelvalue = reader.result;
        };
        reader.onerror = function (error) {
        };
    }

    DataURIToBlob(dataURI: string) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }
    ngOnInit() {
        this.getCurrentCountry();
        this.updateButtonText(this.router.url);
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.updateButtonText(event.urlAfterRedirects);
            });
        this._projectService.projects.subscribe(isRefreshed => {
            if (isRefreshed)
                this.getproject();
        });

        this.route.params.subscribe((params) => {
            this.CalculationNamePristine = this.route.snapshot.queryParamMap.get('cname');
            this.CalculationName = this.route.snapshot.queryParamMap.get('cname');
            this.projectNamePristine = this.route.snapshot.queryParamMap.get('name');
            this.projectName = this.route.snapshot.queryParamMap.get('name');
        });

        var commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
            const emails = control.value.split(',').map(e => e.trim());
            const forbidden = emails.some(email => Validators.email(new FormControl(email)));
            return forbidden ? { 'emailreportabug': { value: control.value } } : null;
        };
        this.reportbugForm = this.formBuilder.group({
            emailreportabug: ['', [Validators.required, commaSepEmail]],
            subjectreportabug: ['', Validators.required],
            locationreportabug: ['', Validators.required],
            bodyreportabug: [''],
            contactFile: [''],
            checkboxFileReportBugEmailCopy: [''],
            checkboxBugLocation: [''],
            checkboxFileReportBugScreenShot: [''],
            hdnimagestr: ['']

        });
        this.reportbugForm.get('checkboxFileReportBugEmailCopy').setValue(false);
        this.contactForm = this.formBuilder.group({
            emailcontactus: ['', [Validators.required, commaSepEmail]],
            subjectcontact: ['', Validators.required],
            contactFile: [''],
            qmsgcontactus: ['', Validators.required],
            checkboxFile: [''],
            checkboxFileReportBugScreenShot: [''],
            checkboxFileReportBugEmailCopy: [''],
            checkboxBugLocation: [''],
            contactSendCopy: [''],
            contactScreenShot: ['']
        });
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;

        this.languages = _.filter(this.localization.languages, l => (<any>l).isDisabled === false);
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.shownLoginNameTitle = this.isImpersonatedLogin ? this.l('YouCanBackToYourAccount') : '';
        this.getProfilePicture();
        this.getproject();
        this.getRecentlyLinkedUsers();

        this.registerToEvents();
        this.getcablesettings();

        $('.connectionddl').on('change', (e) => {
            // alert("x");
        });
        // .m-aside-left--fixed .m-body{
        //     padding-left: 245px !important;
        // }



    }

    getCurrentCountry() {
        this._commonLookupServiceProxy.getIpInfo().subscribe((response) => {
            if (response && response.isSuccess) {
                if (response.data && response.data.country) {
                    let selectedCountry = response.data.country;
                    let selectedCountryObj = this.Countries.find(m => m.iso == selectedCountry);
                    if (selectedCountryObj) {
                        this.countryCurrency = selectedCountryObj.symbol;
                    }
                }
            }
        });
    }

    updateButtonText(url: string): void {

        this.buttonText = 'Introduction Tour';
        if (url.indexOf('/calcs/') > -1 || url.indexOf('/bscalcs/') > -1) {
            this.buttonText = 'Cable Sizing Tour';
        }
        else if (url.indexOf('/automaxd/') > -1) {
            this.buttonText = 'Maximum Demand Tour';
        }
        else if (url.indexOf('/calcp/') > -1) {
            this.buttonText = 'Cable Pulling Tour';
        }
        else if (url.indexOf('/pdccalcs/') > -1 || url.indexOf('/networkcalc/') > -1) {
            this.buttonText = 'Calculation Tour';
        }
        else if (url.indexOf('/arcflash/') > -1) {
            this.buttonText = 'Arc Flash Tour';
        }
    }
    private collectRouteParams(route: ActivatedRoute): any {
        let currentRoute: ActivatedRoute | null = route;
        const params: any = {}; // Object to store parameters from all children

        while (currentRoute) {
            const currentParams = currentRoute.snapshot.params;
            Object.assign(params, currentParams);

            // Move to the next child route
            currentRoute = currentRoute.firstChild;
        }

        return params;
    }
    getproject() {

        this._projectService.getProjectsForDdl().subscribe(result => {
            this.projectOutput = result.items;
            let $singleSelect = $('.c-select');
            $singleSelect.select2({
                width: '100%',
                minimumResultsForSearch: Infinity // disable search
            });
        });

    }
    ngAfterViewInit() {
        //debugger
        $("#m_aside_left_hide_toggle").trigger('click');
        $('#list-subscription-expired-model').modal({
            show: false,
            keyboard: false,
            backdrop: 'static'
        });
        this.checkcalandproj();
        $('#standardAccessModal').on('hidden.bs.modal', () => {
            this.standardAccessModalVisible = false;
            this.standardAccessIframeLoader = false;
        });
    }

    iframeClickHandler() {
        alert("Iframe clicked");
    }


    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });

        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    }

    getCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
        this.tenant = this._appSessionService.tenant;
        this.user = this._appSessionService.user;
        this.premiumUser = this._appSessionService.user.premiumUser;
        if (this.tenant) {
            this.productLiftSsoToken = this._utilsService.getCookieValue(AppConsts.productLife.ssoTokenKey);
            this.feedbackUrl = this.getFeatureRequestUrl();
        }
        else
            this.productLiftSsoToken = undefined;

        this.planExtendedCount = this.user.trialPeriodExtendedCount;
        if (this.premiumUser)
            this.planExtendedCount = 999;

        sessionStorage.setItem('userID', this.user.id.toString());

        this.unRegisteredUser = false;

        if (this.user.userStripeCustomerId === null || this.user.userStripeCustomerId === undefined) {
            this.unRegisteredUser = true;
        }
        else if (this.user.userStripeCustomerId.trim().length == 0 || this.user.userStripeCustomerId.trim() == '0') {
            this.unRegisteredUser = true;
        }

        this.isAdminUser = (this.user.id === 1 || this.user.id === 2);
        this.isPlanAddedManually = this.user.isPlanAddedManually;
        this.hasPlanExpired= MomentHelper.IsSubscriptionDateExpired(this.user.expirationDate);
        this.planDueDays = MomentHelper.GetSubscriptionDueDays(this.user.expirationDate);

        const urlTree = this._router.parseUrl(this._router.url);

        // Extract the URL path without query params
        let currentUrlWithoutQueryParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
        if (!(this._router.url.indexOf('/app/main/usersetup') > -1 || this._router.url.indexOf('/app/main/manage-account') > -1 || this._router.url.indexOf('/app/main/settings') > -1 || this._router.url.indexOf('/projects/subscriptionplans') > -1 || this._router.url.indexOf('/projects/bscalcsubscriptionplans') > -1 || currentUrlWithoutQueryParams == "app/main/projects")) {
            if (this.user.periodEndDate != null && this.user.periodEndDate != undefined
                && this.user.periodEndDate == "Inactive") {
                this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router);
                // this.isActive = false;
            }
            else {
                if (this.user.periodEndDate != null && this.user.periodEndDate != undefined
                    && this.user.periodEndDate != "succeeded") {
                    this.handleFailedOrUnpaidFlow(this.user, this.loaderService, this._subscriptionService, this._companyAccountService, this._router);
                    // this.isActive = false;
                }
            }

            if (!this.isAdminUser) {
                if (this.unRegisteredUser && (this.hasPlanExpired || (this.planDueDays >= -7 && this.planDueDays <= 0))) {
                    if (this.premiumUser && this.hasPlanExpired)
                        this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router);
                    else {
                        this.setExpirePlanDetails();
                    }
                }
            }


        }

        if (this.hasPlanExpired || (this.planDueDays >= -7 && this.planDueDays < 0)) {
            this.isNonStripeUser = (this.user.userStripeCustomerId === null || this.user.userStripeCustomerId === undefined || this.user.userStripeCustomerId == '' || this.user.userStripeCustomerId == '0');
        }

        if (this.premiumUser) {

           
            this.expirationDate =  moment.utc(this.user.expirationDate).local().format('DD/MMM/YYYY').toString();
            this.planLevel = this.user.userStripePlanLevel;
            this.IsUserHasProfessionalPlan = this.user.userStripePlanLevel == 3 ? true : false;
            if (this.user.userStripePlanLevel > 0 && this.user.userStripePlanInterval.toLowerCase().trim() === 'year')
                this.isProPlanUser = this.user.userStripePlanLevel == 3;

            if (this.user.userStripePlanInterval !== null && this.user.userStripePlanInterval !== undefined) {
                if (this.user.userStripePlanInterval.trim().length > 0) {
                    if (this.user.userStripePlanInterval.toLowerCase().trim() === 'month')
                        this.userStripePlanName = this.user.userStripePlanName + ' - Plan - Monthly';
                    else if (this.user.userStripePlanInterval.toLowerCase().trim() === 'year')
                        this.userStripePlanName = this.user.userStripePlanName + ' - Plan - Yearly';
                }
            }
            if (this.user.userStripeSubscriptionStatus !== null && this.user.userStripeSubscriptionStatus !== undefined) {
                this.userStripeSubscriptionStatus = this.user.userStripeSubscriptionStatus.toLowerCase().trim();
                this.isPlanActive = (this.userStripeSubscriptionStatus === 'active')
            }
        }
    }

    setExpirePlanDetails(): void {
        this.expiredPlanHeadingText = '';
        this.expiredPlanBodyText = '';

        this.SetExpiredPlanPopText();

        if (this.expiredPlanHeadingText.trim().length > 0 && this.expiredPlanBodyText.trim().length > 0)
            this.ShowExpiryPopUp();
    };

    ShowExpiryPopUp(): void {
        this._projectService.getExpiryPopUpFlag().subscribe(result => {
            if (!result)
                $('#list-subscription-expired-model').modal('show');
        });
    };

    extendTrialPeriod() {
        this.isLoaderForExtendPlan = true;
        this._planService.extendTrialPeriod().subscribe(result => {
            if (result) {
                window.location.reload();
            }
            this.isLoaderForExtendPlan = false;
        }, () => {
            this.isLoaderForExtendPlan = false;
        });
    }

    SetExpiredPlanPopText(): void {
        if (this.planDueDays > 0) {
            this.expiredPlanHeadingText = this.premiumUser ? 'Your Plan has Expired' : 'Your Free Trial has Ended';
            this.expiredPlanBodyText = this.premiumUser ? 'We have great value monthly and annual plans' : 'Please purchase a plan';
        }
        else {
            this.expiredPlanHeadingText = this.premiumUser ? 'Plan ' : 'Free Trial ';
            this.expiredPlanHeadingText += 'Expiring Soon';

            this.expiredPlanBodyText = this.premiumUser ? 'Your plan ' : 'Your trial period ';
            //this.planDueDays = this.planDueDays - 1;
            switch (this.planDueDays) {
                case -7:
                case -6:
                case -5:
                case -4:
                case -3:
                case -2:
                    this.expiredPlanBodyText += this.premiumUser ? 'will expire in ' + this.planDueDays * -1 + ' days, please renew.'
                    :'will expire in ' + this.planDueDays * -1 + ' days, please purchase.';
                    break;
                case -1:
                    this.expiredPlanBodyText += this.premiumUser ? 'will expire in 1 day, please renew.'
                    :'will expire in 1 day, please purchase.';
                    break;
                case 0:
                    this.expiredPlanBodyText += this.premiumUser ? 'will expire today, please renew.':"will expire today, please purchase.";
                    break;
            }
        }
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }

        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    getRecentlyLinkedUsers(): void {
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(result => {
            this.recentlyLinkedUsers = result.items;
        });
    }

    showLoginAttempts(): void {
        this.loginAttemptsModal.show();
    }

    showLinkedAccounts(): void {
        this.linkedAccountsModal.show();
    }

    changePassword(): void {
        this.changePasswordModal.show();
    }

    changeProfilePicture(): void {
        this.changeProfilePictureModal.show();
    }

    changeMySettings(): void {
        this.mySettingsModal.show();
    }

    logout(): void {
        if (localStorage.getItem("ReportSettingEmails"))
            localStorage.removeItem("ReportSettingEmails");

        this._utilsService.deleteCookie(AppConsts.productLife.ssoTokenKey);
        this._authService.logout();
    }

    GoToPricing(): void {
        if (this.calculationStandard === 0 || this.calculationStandard === 1)
            this._router.navigate(['/app/main/projects/', 'subscriptionplans']);
        else if (this.calculationStandard === 2)
            this._router.navigate(['/app/main/projects/', 'bscalcsubscriptionplans']);
    };

    SetUpgradeLink() {
        if (this.isPlanAddedManually === true) {
            this.GoToPricing();
        }
        else
            this.ManageSub();
    }

    HideExpiryPopUp(): void {
        //this._projectService.setExpiryPopUpFlag().subscribe(result => { this.showExpiryMessage = result; });
        //  alert(this.previousTab);
    };

    FeatureRequest() {
        $('#feedbackPlatform').modal('show');
    }

    getFeatureRequestUrl(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl("https://roadmap-cablepro.elek.com/?sso=" + this.productLiftSsoToken);
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    get chatEnabled(): boolean {
        return (!this._abpSessionService.tenantId || this.feature.isEnabled('App.ChatFeature'));
    }

    subscriptionStatusBarVisible(): boolean {
        return this._appSessionService.tenantId > 0 && (this._appSessionService.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    getSubscriptionExpiringDayCount(): number {
        if (!this._appSessionService.tenant.subscriptionEndDateUtc) {
            return 0;
        }

        return Math.round(moment.utc(this._appSessionService.tenant.subscriptionEndDateUtc).diff(moment().utc(), 'days', true));
    }

    getTrialSubscriptionNotification(): string {
        return this.l('TrialSubscriptionNotification',
            '<strong>' + this._appSessionService.tenant.edition.displayName + '</strong>',
            '<a href=\'/account/buy?editionId=' + this._appSessionService.tenant.edition.id + '&editionPaymentType=' + this.editionPaymentType.BuyNow + '\'>' + this.l('ClickHere') + '</a>');
    }

    getExpireNotification(localizationKey: string): string {
        return this.l(localizationKey, this.getSubscriptionExpiringDayCount());
    }

    getcablesettings() {
        this._settingServiceProxy.getSettings().subscribe(result => {

            if (result.id !== undefined && result.id !== null) {
                this.calculationStandard = result.standards_CableSizing || 0;
                if (!(result.standards_CableSizing === undefined || result.standards_CableSizing === null || result.standards_CableSizing < 0))
                    this.isBCStandard = (result.standards_CableSizing == 2);
                else
                    this.isBCStandard = false;
            }
            else {
                this.isBCStandard = false;
                this.calculationStandard = 0;
            }

            this.getCurrentLoginInformations();

        }, error => {
        });
    }
    showvvoltagerisemodal() {

        this._eventEmitterService.subsVar1 = undefined;
        if (this.user.userStripePlanLevel == 3 || !this.premiumUser) {
            //$(".connectionddl").select2('destroy');
            $("#reportDlgVR").modal('show');
            this._eventEmitterService.onFirstComponentButtonClick1();
        }
        else {
            $('#vrDlg').modal('show');

        }
    }
    // receiveMessage($event) {
    //     this.messagestr = $event;
    //   }
    exportCableSchedule() {
        this._eventEmitterService.onFirstComponentButtonClick();
    }
    CloseModal() {
        $('#contactusDlg').modal('hide');
        $('#reportbugDlg').modal('hide');
    }
    changeModalTitle(title) {
        this.modaltitle = title;
        // alert(2);

    }
    onFileSelected(event) {
        if (event.target.files.length > 0) {
            const filename = event.target.files[0].name;
            const filesize = event.target.files[0].size / 1024 / 1024;
            if ((filename.indexOf('.pdf') > -1 || filename.indexOf('.png') > -1 || filename.indexOf('.jpg') > -1) && filesize < 11) {
                this.hasFile = true;
                document.getElementsByClassName("custom-file-label")[0].innerHTML = filename;
                this.selectedFile = event.target.files[0];
            }
            else {
                this.hasFile = false;
                this.selectedFile = null;
                this.removeFile();
            }
        }
    }
    keyPressReportEmail() {
        this.emailreqmsg = "";
    }
    keyPressReportSubject() {
        this.subjectreqmsg = "";
    }
    keyPressContactEmail() {
        this.contactemailreq = "";
    }
    keyPressContactSubject() {
        this.contactsubreq = "";
    }
    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }
    onSubmitEmail(param) {
        this.emailreqmsg = "";
        this.subjectreqmsg = "";
        this.contactemailreq = "";
        this.contactsubreq = "";
        if (this.validation(param)) {
            this.btnDisabled = true;
            if (param === 'contactus') {
                if ($("#hdnSubjectContact").val() != "") {
                    //debugger;
                    this.subjectcontact = $("#hdnSubjectContact").val();
                    this.contactForm.get('subjectcontact').setValue($("#hdnSubjectContact").val());
                    $("#hdnSubjectContact").val("");
                    this.contactForm.get("contactScreenShot").setValue(false);
                }
                var qmsg = "";
                if (this.contactForm.get('qmsgcontactus').value != null)
                    qmsg = this.contactForm.get('qmsgcontactus').value.replace(/\n/g, '<br />');
                this._fileUploadService.externalFileUploadSendEmail(
                    this.contactForm.get('emailcontactus').value,
                    this.contactForm.get('subjectcontact').value,
                    qmsg,
                    '',
                    this.contactForm.get('contactScreenShot').value,
                    this.contactForm.get('contactSendCopy').value,
                    this.selectedFile,
                    this.imgBase64, this.userName, param).subscribe((res) => {
                        $(".clsscreenshot").show();
                        this.contactForm.get("contactScreenShot").setValue(true);

                        if (res.result.success) {
                            this.subjectcontact = "Cable Pro Web Technical Support";
                            this.contactForm.get('subjectcontact').setValue("Cable Pro Web Technical Support");
                            $('#contactusDlg').modal('hide');
                            $('#HelpEmailSent').modal('show');
                            this.blobFile = null;
                        }
                        else {

                            this.blobFile = null;
                        }
                        this.btnDisabled = false;
                    });
            }
            else if (param === 'bugreport') {
                //debugger
                const file = this.imgBase64;
                var qmsg = "";
                if (this.reportbugForm.get('bodyreportabug').value != null)
                    qmsg = this.reportbugForm.get('bodyreportabug').value.replace(/\n/g, '<br />');
                this._fileUploadService.externalFileUploadSendEmail(

                    this.reportbugForm.get('emailreportabug').value,
                    this.reportbugForm.get('subjectreportabug').value,
                    qmsg,
                    this.reportbugForm.get('locationreportabug').value,
                    this.reportbugForm.get('checkboxFileReportBugScreenShot').value,
                    this.reportbugForm.get('checkboxFileReportBugEmailCopy').value,

                    null,
                    this.reportbugForm.get('hdnimagestr').value, this.userName, param).subscribe((res) => {

                        if (res.result.success) {
                            $('#reportbugDlg').modal('hide');
                            $('#HelpEmailSent').modal('show');
                            this.blobFile = null;
                        }
                        else {

                            this.blobFile = null;
                        }
                        this.btnDisabled = false;
                    });
            }
        }
    }
    validation(param) {
        if (param === 'contactus') {
            if (this.contactForm.get('emailcontactus').value == null || this.contactForm.get('emailcontactus').value == "") {
                this.contactemailreq = "Email is required.";
            }
            if (this.contactForm.get('subjectcontact').value == null || this.contactForm.get('subjectcontact').value == "") {
                this.contactsubreq = "Subject is required.";
            }
            if (this.contactemailreq != "" || this.contactsubreq != "") {
                return false;
            }
            if (this.contactForm.get('emailcontactus').value &&
                this.contactForm.get('subjectcontact').value) {
                return true;
            }
        }
        if (param === 'bugreport') {
            if (this.reportbugForm.get('emailreportabug').value == null || this.reportbugForm.get('emailreportabug').value == "") {
                this.emailreqmsg = "Email is required.";
            }
            if (this.reportbugForm.get('subjectreportabug').value == null || this.reportbugForm.get('subjectreportabug').value == "") {
                this.subjectreqmsg = "Subject is required.";
            }
            if (this.emailreqmsg != "" || this.subjectreqmsg != "") {
                return false;
            }
            // debugger;
            if (this.reportbugForm.get('emailreportabug').value != "") {
                this.reportbugForm.get('emailreportabug').markAsTouched();
                if (this.reportbugForm.controls['emailreportabug'].invalid) {
                    return false;
                }
            }
            //debugger
            if (this.reportbugForm.get('emailreportabug').value &&
                this.reportbugForm.get('subjectreportabug').value) {
                return true;
            }
        }
        return false;
    }
    removeFile() {
        this.attachment.nativeElement.value = '';
        document.getElementsByClassName("custom-file-label")[0].innerHTML = '';
        this.selectedFile = null;
        this.hasFile = false;
    }
    checkcalandproj() {
        var concat = '';
        this.route.queryParams.subscribe(params => {
            concat = '';
            this.reportbugForm.get("checkboxFileReportBugScreenShot").setValue(true);
            this.reportbugForm.get('checkboxFileReportBugEmailCopy').setValue(false);

            this.contactForm.get("contactScreenShot").setValue(true);
            this.contactForm.get('contactSendCopy').setValue(false);
            if ($("#hdnSubjectContact").val() != "") {
                //debugger;
                this.subjectcontact = $("#hdnSubjectContact").val();
                this.contactForm.get('subjectcontact').setValue($("#hdnSubjectContact").val());
                $("#hdnSubjectContact").val("");
                $(".clsscreenshot").show();
            }

            // set("checkboxFileReportBugScreenShot").value=true;
            // this.reportbugForm.get("checkboxBugLocation").disable();
            //alert(this.router.url.indexOf('name'));
            var isinsidecalc = false;
            if (this.router.url.indexOf('name') > -1) {
                concat = 'Project: ' + params['name'];
            }
            if (this.router.url.indexOf('cname') > -1) {
                concat = concat + ' , Calculation: ' + params['cname']
                isinsidecalc = true;
                this.reportbugForm.controls["locationreportabug"].disable();

            }
            else {
                this.reportbugForm.controls["locationreportabug"].enable();
            }
            // debugger
            if (concat != "") {
                $("#lbllocation").show();
                $("#lbllocation").removeAttr("hidden")
                if (document.getElementById('lbllocation') != null)
                    document.getElementById('lbllocation').style.display = 'block';
                if (!isinsidecalc) {
                    $("#lbllocation").hide();
                    $("#lbllocation").attr("hidden", "true")

                    if (document.getElementById('lbllocation') != null)

                        document.getElementById('lbllocation').style.display = 'none';

                }
                this.reportbugForm.controls['locationreportabug'].setValue(concat);
                this.locationswitch = true;
                this.reportbugForm.controls["checkboxBugLocation"].enable();
                this.reportbugForm.controls['checkboxBugLocation'].setValue(true);

            }
            else {
                if (!isinsidecalc) {
                    $("#lbllocation").hide();
                    $("#lbllocation").attr("hidden", "true")

                    if (document.getElementById('lbllocation') != null)
                        document.getElementById('lbllocation').style.display = 'none';

                }
                this.locationswitch = false;
                this.reportbugForm.controls['locationreportabug'].setValue("");
                this.reportbugForm.controls["checkboxBugLocation"].disable();
                this.reportbugForm.controls["locationreportabug"].enable();
                this.reportbugForm.controls['checkboxBugLocation'].setValue(false);
            }
            //this.IsLocationDisabled=true;
        });
    }
    onFlagChange() {
        if (this.reportbugForm.get("checkboxBugLocation").value) {
            var concat = '';
            this.route.queryParams.subscribe(params => {
                concat = '';
                // this.reportbugForm.get("checkboxBugLocation").disable();
                if (this.router.url.indexOf('name') > -1) {
                    concat = 'Project: ' + params['name'];
                }
                if (this.router.url.indexOf('cname') > -1) {
                    concat = concat + ' , Calculation: ' + params['cname']
                    this.reportbugForm.controls["locationreportabug"].disable();
                    //alert();
                }
                else {
                    this.reportbugForm.controls["locationreportabug"].enable();
                }
                this.reportbugForm.controls['locationreportabug'].setValue(concat);

                if (concat != "") {
                    this.locationswitch = true;
                    this.reportbugForm.controls["checkboxBugLocation"].enable();
                    this.IsActiveBugLocation = true;
                    this.reportbugForm.controls['checkboxBugLocation'].setValue(this.IsActiveBugLocation);
                }
                else {
                    this.locationswitch = false;
                    this.reportbugForm.controls["checkboxBugLocation"].disable();
                    this.reportbugForm.controls["locationreportabug"].enable();
                    this.IsActiveBugLocation = false;
                    this.reportbugForm.controls['checkboxBugLocation'].setValue(this.IsActiveBugLocation);
                }
                //this.IsLocationDisabled=true;
            });
        }
        else {
            // document.getElementById('lbllocation').style.display='none';
            this.reportbugForm.controls['locationreportabug'].setValue('');
            this.reportbugForm.controls["locationreportabug"].enable();
            this.IsActiveBugLocation = false;
            this.reportbugForm.controls['checkboxBugLocation'].setValue(this.IsActiveBugLocation);
            //this.IsLocationDisabled=false;
        }
    }
    downloadImage() {
        setTimeout(
            () => {
                this.capture();
                this.blobFile = "<html>" + $("html").html() + "</html>";
            },
            1000);

    }

    showLoader(): void {
        if (!this.isRouteMatching('/app\/main\/projects\/(\\d+)\/calcs\\?name=') && !(this._router.url.indexOf('/calcs/') > -1 || this._router.url.indexOf('/maxd/') > -1 || this._router.url.indexOf('/automaxd/') > -1 || this._router.url.indexOf('/arcflash/') > -1 || this._router.url.indexOf('/bscalcs/') > -1 || this._router.url.indexOf('/pdccalcs/') > -1 || this._router.url.indexOf('/calcp/') > -1 || this._router.url.indexOf('/networkcalc/') > -1))
            this.loaderService.show();
        else
            this.loaderService.showHeader();
    }
    hideLoader(): void {
        if (!this.isRouteMatching('/app\/main\/projects\/(\\d+)\/calcs\\?name=') && !(this._router.url.indexOf('/calcs/') > -1 || this._router.url.indexOf('/maxd/') > -1 || this._router.url.indexOf('/automaxd/') > -1 || this._router.url.indexOf('/arcflash/') > -1 || this._router.url.indexOf('/bscalcs/') > -1 || this._router.url.indexOf('/pdccalcs/') > -1 || this._router.url.indexOf('/calcp/') > -1 || this._router.url.indexOf('/networkcalc/') > -1))
            this.loaderService.hide();
        else
            this.loaderService.hideHeader();
    }

    contactSupport() {
        let element = document.querySelector("#capture");
        let imgstring: any = '';
        let me = this;
        // me.showSpinner = true;
        this.showLoader();
        this.captureService.getImage(document.body, true).pipe(tap((img) => {
            me.imgBase64 = img;
            me.reportbugForm.get("hdnimagestr").setValue(img);
            me.modaltitle = "Contact Support";
            // me.showSpinner = false;
            this.hideLoader();
            this.subjectcontact = "Cable Pro Web Technical Support";
            this.contactForm.get('subjectcontact').setValue("Cable Pro Web Technical Support");
            this.contactForm.get("qmsgcontactus").setValue("");
            $("#contactusDlg").modal("show");
        })).subscribe();

        // (html2canvas as any)(document.body).then(function (canvas) {
        //     // Convert the canvas to blob

        //     canvas.toBlob(function (blob) {
        //         let reader = new FileReader();
        //         reader.readAsDataURL(blob);
        //         reader.onload = function () {

        //             //me.modelvalue = reader.result;
        //             imgstring = reader.result;
        //             me.imgBase64 = imgstring;
        //             me.reportbugForm.get("hdnimagestr").setValue(imgstring);
        //             me.modaltitle = "Contact Support";
        //             me.showSpinner = false;
        //             $("#contactusDlg").modal("show");
        //         };
        //         reader.onerror = function (error) {
        //             me.showSpinner = false;
        //         };

        //     })

        // });


    }

    reportBug() {
        let me = this;
        // me.showSpinner = true;
        this.showLoader();
        this.captureService.getImage(document.body, true).pipe(tap((img) => {
            me.imgBase64 = img;
            me.reportbugForm.get("hdnimagestr").setValue(img);
            me.modaltitle = "Report a Bug";
            // me.showSpinner = false;
            this.hideLoader();
            $("#reportbugDlg").modal("show");
        })).subscribe();
    }

    lvReport(report: number) {
        this._settingService.reportOption = report;

        this._settingService.changeReport(this._settingService.reportOption);

    }

    isRouteMatching(pattern: string) {
        return this._router.url.search(new RegExp(pattern)) > -1;
    }
    EditProject(): void {
        this.projectInput.name = this.projectName;
        //this.projectNamePristine=this.projectName;
        this.projectInput.id = this.ProjectId;
        this._projectService.editProjectName(this.projectInput).subscribe(result => {
            // $('#new-project').modal('hide');
            //this.Resetform();
            //this.getproject('edit');
            const queryParams: Params = { name: this.projectName };
            this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                }
            );
            this._projectService.refreshSidebarProjects();
            this.isCalculationPopoverVisible = false;

        }, error => {
        });
    }
    EditCalcuation(): void {
        this.calculationInput.projectId = this.ProjectId ? this.ProjectId : 0;
        this.calculationInput.name = this.CalculationName;
        this.calculationInput.id = this.CalculationId;
        if (this._router.url.indexOf('/networkcalc/') > -1) {
            this.calculationInput.calculationType = 7;
        }
        else {
            this.calculationInput.calculationType = 0;
        }
        this._calculationService.editCalculationName(this.calculationInput).subscribe(result => {
            this._calculationService.refreshSidebarCalculations();
            this._projectService.refreshSidebarProjects();
            this._projectService.refreshHeaderProject();
            this.isCalculationPopoverVisible = false;
            const queryParams: Params = { cname: this.CalculationName, name: result.projectName };
            var url = '';
            var otherParams = '?name=' + result.projectName + '&cname=' + this.CalculationName;
            if (this._router.url.indexOf('/calcs/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/calcs/' + result.id;
            }
            else if (this._router.url.indexOf('/bscalcs/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/bscalcs/' + result.id;
            }
            else if (this._router.url.indexOf('/automaxd/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/automaxd/' + result.id;
            }
            else if (this._router.url.indexOf('/pdccalcs/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/pdccalcs/' + result.id;
            }
            else if (this._router.url.indexOf('/calcp/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/calcp/' + result.id;
            }
            else if (this._router.url.indexOf('/networkcalc/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/networkcalc/' + result.id;
            }
            else if (this._router.url.indexOf('/arcflash/') >= 1) {
                url = '/app/main/projects/' + result.projectId + '/arcflash/' + result.id;
            }
            else {
                return;
            }
            let fullUrl = url;
            this.ProjectId = result.projectId;
            this.router.navigate(
                [fullUrl],
                {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                }
            );
            //this.calculationInput.projectId = result.projectId;
        });
    }
    onProjectPopoverHidden() {
        this.EditProject();
    }
    keyEnterCalculation() {
        if (this.isCalculationPopoverVisible) {
            this.myPopover.hide();
            this.EditCalcuation();
        }
    }
    keyEnterProject() {
        if (this.isCalculationPopoverVisible) {
            this.myProjectPopover.hide();
            this.EditProject();
        }
    }
    onCalculationPopoverHidden() {
        this.EditCalcuation();
    }

    onBlur(selected) {
        if (selected && selected.name)
            this.calculationInput.projectName = selected.name;
    }

    CreateTeamAccount() {
        if (this.hasPlanExpired) {
            this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router)
            return;
        }
        if (this.isPlanAddedManually) {
            this._modalPopupService.error("Manage Subscription", "Your subscription has been paid via direct invoice. Please contact <a href='mailto:sales@elek.com'>sales@elek.com</a> to make changes.", "Close");
        }
        else {
            this._modalPopupService.confirm("Create a Team Account", "In the next window, you can purchase licences for your team. After that, you can invite new members from your Account Settings page.", (value) => {
                if (value) {
                    this.ManageSubscription(this.user, this.loaderService, this._subscriptionService, "subscription");
                }
            }, "fa-info-circle text-primary", "Proceed", "btn-primary");
        }
    }

    ManageSub() {
        if (this.hasPlanExpired) {
            this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router)
            return;
        }
        this.ManageSubscription(this.user, this.loaderService, this._subscriptionService, "");
    }

    UpgradeSub() {
        if (this.hasPlanExpired) {
            this.handleUnlicencesOrExpiredFlow(this.user, this.calculationStandard, this._companyAccountService, this._router)
            return;
        }
        this.UpgradeSubscription(this.user, this.loaderService, this._subscriptionService, this._companyAccountService);
    }

    timeLeft: number = 300;  // 5 minutes in seconds (5 * 60)
    minutes: number;
    seconds: string;
    private intervalId: any;
    countdownAnimationFrame: any;
    standardAccess() {
        if (!this._appSessionService.user.premiumUser) {
            this._modalPopupService.confirm("Access to Standards", "You can access the latest electrical standards by subscribing to an annual plan.", (value) => {
                if (value) {
                    this.GoToPricing();
                }
            }, "", "Go to Pricing", "btn-primary", "Cancel");
        }
        else if (this._appSessionService.user.premiumUser && this._appSessionService.user.userStripePlanInterval.toLowerCase().trim() === 'month') {
            this._modalPopupService.confirm("Access to Standards", "You can access the latest electrical standards by subscribing to an annual plan.", (value) => {
                if (value) {
                    this.ManageSubscription(this.user, this.loaderService, this._subscriptionService, "subscription");
                }
            }, "", "View our Annual Plans", "btn-primary", "Cancel");
        }
        else if (this._appSessionService.user.premiumUser && this._appSessionService.user.userStripePlanInterval.toLowerCase().trim() === 'year') {
            this.standardAccessApiloader = true;
            this._commonLookupServiceProxy.StandardAccess().subscribe((response) => {
                if (response && response.isSuccess) {
                    $('#standardAccessModal').modal({
                        show: true,
                        keyboard: false,
                        backdrop: 'static'
                    });
                    this.timeLeft = 300;
                    this.startCountdown();
                    this.standardAccessModalVisible = true;
                    this.standardAccessIframeLoader = true;
                }
                else if (response && !response.isSuccess && response.code == "Maximum_Users") {
                    this._modalPopupService.error("Access to Standards", "There are currently too many users accessing the standards. Please try again in 5 minutes.", "Close");
                }
                else if (response && !response.isSuccess && response.code == "Multiple_Tabs") {
                    this._modalPopupService.error("Access to Standards", "You have already opened this content in another tab or browser. Please close that to proceed.", "Close");
                }
                else {

                }
            }, () => { }, () => { this.standardAccessApiloader = false; });

        }
    }

    startCountdown() {
        const endTime = Date.now() + this.timeLeft * 1000; // Calculate the end time

        const updateCountdown = () => {
            const currentTime = Date.now();
            this.timeLeft = Math.max(Math.floor((endTime - currentTime) / 1000), 0); // Calculate remaining time

            this.updateTimeDisplay();

            if (this.timeLeft > 0) {
                // Continue the loop if there's time left
                this.countdownAnimationFrame = requestAnimationFrame(updateCountdown);
            } else {
                this.handleTimeUp(); // Handle time up
            }
        };

        this.countdownAnimationFrame = requestAnimationFrame(updateCountdown); // Start the animation loop
    }

    updateTimeDisplay() {
        this.minutes = Math.floor(this.timeLeft / 60);  // Calculate minutes
        this.seconds = (this.timeLeft % 60).toString().padStart(2, '0');
    }

    handleTimeUp() {
        this.timeLeft = 0;
        cancelAnimationFrame(this.countdownAnimationFrame); // Cancel the animation frame
        clearInterval(this.intervalId);
        this._commonLookupServiceProxy.LogoutOldSessionsOfUserAsync();
        this._commonLookupServiceProxy.LogoutStandardAccess().subscribe(response => {
            if (response && response.body)
                this.handleHtmlResponse(response.body.toString());
        }, error => {
            console.error('LogoutStandardAccess Logout failed', error);
        });
        $('#standardAccessModal').modal('hide');
    }

    getStandardsRequestUrl(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.ServicesBaseUrl}/ip-check?ts=${new Date().getTime()}&token=${abp.auth.getToken()}`);
    }

    requestStandardAccess() {
        this.standardAccessloader = true;
        let me = this;

        this.captureService.getImage(document.body, true).pipe(tap((img) => {
            me.imgBase64 = img;
            me.reportbugForm.get("hdnimagestr").setValue(img);
            me.modaltitle = "Contact Support";
            this.standardAccessloader = false;
            this.subjectcontact = "Cable Pro Web Request a Standard";
            this.contactForm.get('subjectcontact').setValue(this.subjectcontact);
            this.contactForm.get("qmsgcontactus").setValue("I would like to request the following standard:");
            this.contactForm.get("contactScreenShot").setValue(false);
            $("#contactusDlg").modal("show");
        })).subscribe();
    }

    onIframeLoad() {
        setTimeout(() => {
            this.standardAccessIframeLoader = false;
        }, 5000);
    }

    // Use @HostListener to capture the window close event
    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        let isShown = $('#standardAccessModal').hasClass('show');
        if (isShown) {
            this._commonLookupServiceProxy.LogoutOldSessionsOfUserAsync();
            this._commonLookupServiceProxy.LogoutStandardAccess().subscribe(response => {
                if (response && response.body)
                    this.handleHtmlResponse(response.body.toString());
            }, error => {
            });
        }
    }

    handleHtmlResponse(html: string) {
        // Parse the HTML response and insert it into the DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Find the form element
        const form = doc.querySelector('form#LogoutForm_Form');

        if (form) {
            // Manually extract the form action, method, and form data
            const actionUrl = form.getAttribute('action');
            const method = form.getAttribute('method');
            const formData = new FormData();

            // Find all input elements inside the form and add them to the FormData object
            const inputs = form.querySelectorAll('input');
            inputs.forEach((input: HTMLInputElement) => {
                if (input.name) {
                    if (input.name == "BackURL")
                        formData.append(input.name, "/");
                    else
                        formData.append(input.name, input.value);
                }
            });

            // Now make the HTTP POST request to submit the form
            if (actionUrl && method === 'post') {
                this.submitForm(actionUrl, formData);
            } else {
                console.log('Form action or method is not correct.');
            }
        } else {
            console.log('Form not found.');
        }
    }

    submitForm(actionUrl: string, formData: FormData) {
        // Set headers
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'X-Bypass-Error-Handler': 'true'
        });
        let postAUrl = environment.ServicesBaseUrl + actionUrl;
        this.http.post(postAUrl, formData, {
            headers,
            observe: 'response',  // to capture the full response including headers
            withCredentials: true,
            // ensures cookies are sent with the request,
        }).subscribe(
            (response: any) => {
            },
            error => {
            }
        );
    }

    OpenHelpUrl() {
        window.open("https://help-cpw.elek.com/cpw/?t=" + this._appSessionService.helpUrlToken, '_blank');
    }


}
