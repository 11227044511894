
import { mergeMap as _observableMergeMap, catchError as _observableCatch, debounce, debounceTime, takeUntil } from 'rxjs/operators';

import { Observable, from as _observableFrom, throwError as _observableThrow, of as _observableOf, Subject, BehaviorSubject } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpResponseBase, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {
    ProtectiveXmlInput,
    CableproProjectServiceProxy, SettingServiceProxy, CalculationServiceProxy, CableproServiceProxy, DRFServiceServiceProxy, SessionServiceProxy,
    BSServiceProxy, SubscriptionServiceProxy, BSDRFServiceServiceProxy, ProjectInput, ProjectOutput, SettingInput, SettingOutput, ManufacturerOutput, MCBOutput, CalculationInput, CalculationOutput,
    ListResultDtoOfProjectOutput, ListResultDtoOfCalculationOutput, BreakingOutput, LoadData, CableproOutput, GetCurrentLoginInformationsOutput, CableproXmlInput,
    ListResultDtoOfLoadInput, ListProtectiveXmlInput, ListCoordinationInput, ListResultDtoOfSubGroupInput, ListResultDtoOfPlanOutput, PlanOutput, CheckOutSuccessOutput, ListResultDtoOfInvoiceOutput,
    VersionServiceProxy, ListResultDtoOfVersionOutput, ListProtectiveInput, ListResultDtoOfCouponOutput, CalculationReportInput, TeamMemberInput, TeamMemberOutput, DeviceOutput, ProtectiveOtherDetail, ListUserPreferences, UserPreference, RecentCalculationOutput,
    ProtectiveDeviceData
} from '@shared/service-proxies/service-proxies';
import { Router, ActivationEnd } from '@angular/router';

class Table23InstallationModel {
    name: string;
    value: number;
    img: string;
    desc2: string;
    desc: string;
}

@Injectable()
export class ProductService {

    private recentProjectsSub = new BehaviorSubject<Observable<ProjectOutput[]>>(undefined);
    public recentProjects = this.recentProjectsSub.asObservable();

    private projectsSub = new BehaviorSubject<boolean>(undefined);
    public projects = this.projectsSub.asObservable();

    private projectOutput: any;
    private teamMemberOutput: any;
    private isExpiryPopUpHidden: boolean = false;
    private isTeamPopUpHidden: boolean = false;
    constructor(private _cableProProjectservice: CableproProjectServiceProxy) {
    }

    public refreshSidebarProjects() {
        let projects = this.getRecentProjects();
        this.recentProjectsSub.next(projects);
    }

    public getProjects(): Observable<ListResultDtoOfProjectOutput> {
        // if (this.projectOutput !== undefined) {
        //     return _observableOf(this.projectOutput);
        // }
        return this._cableProProjectservice.getProjects().pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = response_;
            this.projectsSub.next(true);
            return _observableOf(this.projectOutput);
        }));
    }

    public refreshHeaderProject() {
        this.projectsSub.next(true);
    }

    public getRecentProjects(): Observable<ProjectOutput[]> {
        return this._cableProProjectservice.getRecentProjects();
    }

    public projectCopyUtility(model: any) {
        return this._cableProProjectservice.ProjectCopyUtility(model).pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }

    public getProjectsForDdl(): Observable<ListResultDtoOfProjectOutput> {
        return this._cableProProjectservice.getProjectsForDdl().pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = response_;
            return _observableOf(this.projectOutput);
        }));
    }

    public addProject(projectInput: ProjectInput) {
        return this._cableProProjectservice.createProject(projectInput).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }

    public copyProject(projectId: number) {
        return this._cableProProjectservice.copyProject(projectId).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }


    public editProject(projectInput: ProjectInput) {
        return this._cableProProjectservice.editProject(projectInput).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public editProjectName(projectInput: ProjectInput) {
        return this._cableProProjectservice.editProjectName(projectInput).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public addTeamMemberToProject(projectInput: ProjectInput) {
        return this._cableProProjectservice.addTeamMemberToProject(projectInput).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public checkValidTeamMember(teamMmberInput: TeamMemberInput) {
        return this._cableProProjectservice.checkValidTeamMember(teamMmberInput).pipe(_observableMergeMap((response_: any) => {
            this.teamMemberOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public deleteProject(projectId: number) {
        return this._cableProProjectservice.deleteProject(projectId).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }

    public undoDeleteProject(projectId: number) {
        return this._cableProProjectservice.undoDeleteProject(projectId).pipe(_observableMergeMap((response_: any) => {
            this.projectOutput = undefined;
            return _observableOf(response_);
        }));
    }

    public setExpiryPopUpFlag(): Observable<boolean> {
        this.isExpiryPopUpHidden = true;
        return _observableOf(this.isExpiryPopUpHidden);
    }
    public getExpiryPopUpFlag(): Observable<boolean> {
        return _observableOf(this.isExpiryPopUpHidden);
    }

    public setTeamPopUpFlag(): Observable<boolean> {
        this.isTeamPopUpHidden = true;
        return _observableOf(this.isTeamPopUpHidden);
    }
    public getTeamPopUpFlag(): Observable<boolean> {
        return _observableOf(this.isTeamPopUpHidden);
    }
}

@Injectable()
export class SettingService {
    private settingOutput: any;
    private chksettingOutput: any;
    private manufacturerOutput: ManufacturerOutput[] = [];
    private modelOutput: ManufacturerOutput[] = [];
    private deviceOutput: DeviceOutput = new DeviceOutput();
    private mcbOutput: MCBOutput[] = [];
    private mcbOutput1: MCBOutput[] = [];
    private mcbOutput2: MCBOutput[] = [];
    private mcbOutput3: MCBOutput[] = [];
    private messageSourceReport = new BehaviorSubject<number>(0);
    currentMessageReport = this.messageSourceReport.asObservable();
    public coordinationData: ListCoordinationInput = new ListCoordinationInput();
    private loadData: any
    private fileOutput: MCBOutput[] = [];
    public loadProtectiveXmlInput: ProtectiveXmlInput = new ProtectiveXmlInput();
    public loadProtectiveOtherDetail: ProtectiveOtherDetail = new ProtectiveOtherDetail();

    private curveOutput: MCBOutput[] = [];
    private polesTableOutput: MCBOutput[] = [];
    private xmlData: string = "";
    private operationalTableOutput: MCBOutput[] = [];
    private breakingOutput: BreakingOutput[] = [];
    private familyOutput: ManufacturerOutput[] = [];
    public hideArcFlashReport: boolean = true;
    public reportOption: number;
    private _showNavs = true;
    constructor(private _settingservice: SettingServiceProxy, private _calculationService: CalculationServiceProxy) {

    }

    get showNav(): boolean {
        return this._showNavs;
    }

    set showNav(value: boolean) {
        this._showNavs = value;
    }

    public getUserPreferences(userID: number, projectID: number | null): Observable<ListUserPreferences> {
        return this._settingservice.getUserPreferences(userID, projectID);

    }
    public setUserPreferences(userpreference: UserPreference): Observable<ListUserPreferences> {
        return this._settingservice.setUserPreferences(userpreference);

    }
    changeReport(message: number) {
        this.messageSourceReport.next(message);
    }

    public getSettings(): Observable<SettingOutput> {
        if (this.settingOutput !== undefined) {
            return _observableOf(this.settingOutput);
        }
        return this._settingservice.getSettings().pipe(_observableMergeMap((response_: any) => {
            this.settingOutput = response_;
            return _observableOf(this.settingOutput);
        }));
    }
    public checkSettings(): Observable<SettingOutput> {
        if (this.chksettingOutput !== undefined) {
            return _observableOf(this.chksettingOutput);
        }
        return this._settingservice.checkSettings().pipe(_observableMergeMap((response_: any) => {
            this.chksettingOutput = response_;
            return _observableOf(this.chksettingOutput);
        }));
    }
    public getDeleteFile(manufacturer: string, fileType: string): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.manufacturerOutput = [];
        return this._settingservice.getDeleteFile(manufacturer, fileType).pipe(_observableMergeMap((response_: any) => {
            this.manufacturerOutput.push(response_);
            return _observableOf(this.manufacturerOutput);
        }));
    }
    public getManufacturer(canHaveHV: boolean = false): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.manufacturerOutput = [];
        return this._settingservice.getManufacturers(canHaveHV).pipe(_observableMergeMap((response_: any) => {
            this.manufacturerOutput.push(response_);
            return _observableOf(this.manufacturerOutput);
        }));
    } //getBreakingByManufacturerId

    public getUploadedFiles(): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.fileOutput = [];
        return this._settingservice.getUploadedFiles().pipe(_observableMergeMap((response_: any) => {
            this.fileOutput.push(response_);
            return _observableOf(this.fileOutput);
        }));
    } //
    public getProtectiveData(): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.loadProtectiveXmlInput = null;
        return this._settingservice.getProtectiveData().pipe(_observableMergeMap((response_: any) => {
            this.loadProtectiveXmlInput = response_;
            return _observableOf(this.loadProtectiveXmlInput);
        }));
    } //


    public getBreakingByManufacturerId(manufacturerId: number, familyId: string, modelId: number,
        rating: string,
        curve: string): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.breakingOutput = [];
        return this._settingservice.getBreakingByManufacturerId(manufacturerId, familyId, modelId, rating, curve).pipe(_observableMergeMap((response_: any) => {
            this.breakingOutput.push(response_);
            return _observableOf(this.breakingOutput);
        }));
    }
    public getRatingByManufacturer(manufacturerId: number, familyId: string, modelId: number): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }

        this.mcbOutput1 = [];
        return this._settingservice.getRatingByManufacturer(manufacturerId, familyId, modelId).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput1 = [];
            this.mcbOutput1.push(response_);
            return _observableOf(this.mcbOutput1);
        }));
    }



    public getOperationalTableByManufacturer(manufacturerId: number, familyId: string, modelId: number, rating: number, curve: string): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.operationalTableOutput = [];
        return this._settingservice.getOperationalTableByManufacturer(manufacturerId, familyId, modelId, rating, curve).pipe(_observableMergeMap((response_: any) => {
            this.operationalTableOutput.push(response_);
            return _observableOf(this.operationalTableOutput);
        }));
    }
    public getPolesTableByManufacturer(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.polesTableOutput = [];
        return this._settingservice.getPolesTableByManufacturer(manufacturerId, familyId, modelId, rating, curve).pipe(_observableMergeMap((response_: any) => {
            this.polesTableOutput.push(response_);
            return _observableOf(this.polesTableOutput);
        }));
    }
    public getXml(manufacturerId: number, familyId: string, modelId: number, rating: string, curve: string

        , tmir: string, tmim: string, mim: string, currenttolerance: string, timetolerance: string, timetobreak: string
        , eratingplug: string, eltbool: string, estbool: string, eintbool: string
        , eio: string, eir: string
        , etr: string, eisd: string
        , etsd: string, ei2tcurve: string
        , eli: string, idmtl: string

    ): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.xmlData = "";
        return this._settingservice.getXmlData(manufacturerId, familyId, modelId, rating, curve, tmir, tmim, mim, currenttolerance, timetolerance, timetobreak
            , eratingplug, eltbool, estbool, eintbool
            , eio, eir
            , etr, eisd
            , etsd, ei2tcurve
            , eli, idmtl).pipe(_observableMergeMap((response_: any) => {
                this.xmlData = response_;
                return _observableOf(this.xmlData);
            }));
    }

    public SearchProtectiveDevices(keyword: string, startAt: number, is_hv_fuse: boolean = false): Observable<ProtectiveDeviceData[]> {
        return this._settingservice.SearchProtectiveDevices(keyword, startAt, is_hv_fuse).pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }

    public getCurveByManufacturer(manufacturerId: number, familyId: string, modelId: number, rating: string): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.curveOutput = [];
        return this._settingservice.getCurveByManufacturer(manufacturerId, familyId, modelId, rating).pipe(_observableMergeMap((response_: any) => {
            this.curveOutput.push(response_);
            return _observableOf(this.curveOutput);
        }));
    }
    public getModelByManufacturer(manufacturerId: number, familyId: string): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.modelOutput = [];

        return this._settingservice.getModelByManufacturer(manufacturerId, familyId).pipe(_observableMergeMap((response_: any) => {
            this.modelOutput.push(response_);
            return _observableOf(this.modelOutput);
        }));
    }
    public getModelByManufacturernew(manufacturerId: number, familyId: string, filterBreaking: number, isHV: boolean = false): Observable<any> {
        // if (this.deviceOutput !== undefined) {
        //     return _observableOf(this.deviceOutput);
        // }
        this.deviceOutput = new DeviceOutput();

        return this._settingservice.getModelByManufacturernew(manufacturerId, familyId, filterBreaking, isHV).pipe(
            _observableMergeMap((response_: any) => {
                this.deviceOutput = response_;
                return _observableOf(this.deviceOutput);
            }));
    }

    public getFamilyByManufacturer(manufacturerId: number, isHV: boolean = false): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.familyOutput = [];
        return this._settingservice.getFamilyByManufacturer(manufacturerId, isHV).pipe(_observableMergeMap((response_: any) => {
            this.familyOutput.push(response_);
            return _observableOf(this.familyOutput);
        }));
    }

    public createSetting(settingInput: SettingInput) {
        return this._settingservice.createSetting(settingInput).pipe(_observableMergeMap((response_: any) => {
            this.settingOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public storeProtectiveXml(input: ProtectiveXmlInput | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput2 = [];

        return this._settingservice.storeProtectiveXml(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput2 = [];
            this.mcbOutput2.push(response_);
            return _observableOf(this.mcbOutput2);
        }));
    }

    public copyUtility(calculationInput: any) {
        return this._calculationService.CopyUtility(calculationInput).pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }

    public downloadInputXml(calculationInput: any) {
        return this._calculationService.DownloadInputXml(calculationInput).pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }

    public StoreCoordinationDetail(input: ListCoordinationInput | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.coordinationData = new ListCoordinationInput();

        return this._settingservice.storeCoordinationDetail(input).pipe(_observableMergeMap((response_: any) => {
            this.coordinationData = response_;
            return _observableOf(this.coordinationData);
        }));
    }
    public storeProtectiveXmlTwo(input: ListProtectiveInput | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput3 = [];

        return this._settingservice.storeProtectiveXmlTwo(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput3 = [];
            this.mcbOutput3.push(response_);
            return _observableOf(this.mcbOutput3);
        }));
    }

    public storeNetworkProtectiveXml(input: ListProtectiveInput | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput3 = [];

        return this._settingservice.storeNetworkProtectiveXml(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput3 = [];
            this.mcbOutput3.push(response_);
            return _observableOf(this.mcbOutput3);
        }));
    }

    public storeProtectiveOtherDetail(input: ProtectiveOtherDetail | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.loadProtectiveOtherDetail = new ProtectiveOtherDetail();

        return this._settingservice.storeProtectiveOtherDetail(input).pipe(_observableMergeMap((response_: any) => {
            this.loadProtectiveOtherDetail = response_;
            return _observableOf(this.loadProtectiveOtherDetail);
        }));
    }
    public getProtectiveDataByCalculationId(calculationId: number): Observable<ListProtectiveXmlInput> {
        /*if (this.loadData !== undefined && this.calculationId === calculationId) {
            return _observableOf(this.loadData);
        }*/
        return this._settingservice.getProtectiveDataByCalculationId(calculationId).pipe(_observableMergeMap((response_: any) => {
            this.loadData = response_;

            return _observableOf(this.loadData);
        }));
    }
    public getNetworkProtectiveDataByNodeId(calculationId: number, nodeId: string): Observable<ListProtectiveXmlInput> {
        /*if (this.loadData !== undefined && this.calculationId === calculationId) {
            return _observableOf(this.loadData);
        }*/
        return this._settingservice.getNetworkProtectiveDataByNodeId(calculationId, nodeId).pipe(_observableMergeMap((response_: any) => {
            this.loadData = response_;

            return _observableOf(this.loadData);
        }));
    }
    public updateProtectiveXmlTwo(input: ListProtectiveInput | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput = [];

        return this._settingservice.updateProtectiveXmlTwo(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput = [];
            this.mcbOutput.push(response_);
            return _observableOf(this.mcbOutput);
        }));
    }
    public updateNetworkProtectiveXmlTwo(input: ListProtectiveInput | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput = [];

        return this._settingservice.updateNetworkProtectiveXmlTwo(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput = [];
            this.mcbOutput.push(response_);
            return _observableOf(this.mcbOutput);
        }));
    }

    public updateNetworkProtectiveForConnectionTime(input: any | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput = [];

        return this._settingservice.updateNetworkProtectiveForConnectionTime(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput = [];
            this.mcbOutput.push(response_);
            return _observableOf(this.mcbOutput);
        }));
    }

    public updateNetworkProtectiveXml(input: any | null | undefined): Observable<any> {
        // if (this.manufacturerOutput !== undefined) {
        //     return _observableOf(this.manufacturerOutput);
        // }
        this.mcbOutput = [];

        return this._settingservice.updateNetworkProtectiveXml(input).pipe(_observableMergeMap((response_: any) => {
            this.mcbOutput = [];
            this.mcbOutput.push(response_);
            return _observableOf(this.mcbOutput);
        }));
    }

    public UpdateNetworkProtectionCoordinationXml(input: any | null | undefined): Observable<any> {
        return this._settingservice.UpdateNetworkProtectionCoordinationXml(input).pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }

    public updateSettings(): Observable<SettingOutput> {
        return this._settingservice.getSettings().pipe(_observableMergeMap((response_: any) => {
            this.settingOutput = undefined;
            return _observableOf(this.settingOutput);
        }));
    }
    public hidenavbar() {
        if ($("#m_ver_menu ul>li").length == 0) {
            // $(".m-brand__tools").css('display','none');
            $("#m_aside_left_hide_toggle").css('visibility', 'hidden');
            $("#m_aside_left_offcanvas_toggle").css('visibility', 'hidden');
            $("#m_aside_left").css('display', 'none');
            $("#DivAdmin").addClass("nonadmincls");
        }
        else {
            $(".m-brand__tools").css('display', 'table-cell');
            $("#m_aside_left").css('display', 'block');
            $("#DivAdmin").removeClass("nonadmincls");
        }

    }
    //public getfirstTimeSettings(): Observable<SettingOutput> {
    //    return this._settingservice.getSettings().pipe(_observableMergeMap((response_: any) => {
    //        this.settingOutput = response_;
    //        return _observableOf(this.settingOutput);
    //    }));
    //}
}
@Injectable()
export class HttpCancelService {

    private pendingHTTPRequests$ = new Subject<void>();

    constructor() { }

    // Cancel Pending HTTP calls
    public cancelPendingRequests() {
        this.pendingHTTPRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.pendingHTTPRequests$.asObservable();
    }

}
@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

    constructor(router: Router,
        private httpCancelService: HttpCancelService) {
        router.events.subscribe(event => {
            // An event triggered at the end of the activation part of the Resolve phase of routing.
            if (event instanceof ActivationEnd) {
                // Cancel pending calls
                this.httpCancelService.cancelPendingRequests();
            }
        });
    }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
    }
}

@Injectable()
export class CalculationService {
    private calculationOutput: any;
    private allCalculationsOutput: any;
    private cableproLoadInput: any;
    private cableproSubGroupInputById: any;
    private cableproSubGroupInputAll: any;
    private loadData: any;
    private projectid: any;
    private calculationId: any;
    private loadId: any;
    private voltageDrop: any;
    private currentValue: any;
    private calculationHeaderLabel: any;
    private cableScheduleExportData: any = [];

    private recentCalculationsSub = new BehaviorSubject<Observable<RecentCalculationOutput[]>>(undefined);
    public recentCalculations = this.recentCalculationsSub.asObservable();    

    constructor(private _calculationService: CalculationServiceProxy) {

    }    

    public refreshSidebarCalculations() {
        let projects = this.getRecentCalculations();
        this.recentCalculationsSub.next(projects);
    }

    public getCalculations(projectId: number): Observable<ListResultDtoOfCalculationOutput> {
        // if (this.calculationOutput !== undefined && this.projectid === projectId) {
        //     return _observableOf(this.calculationOutput);
        // }
        return this._calculationService.getCalculations(projectId).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = response_;
            this.projectid = projectId;
            return _observableOf(this.calculationOutput);
        }));
    }
    public getRecentCalculations(): Observable<RecentCalculationOutput[]> {
        return this._calculationService.getRecentCalculations();
    }

    public getAllCalculations(): Observable<ListResultDtoOfCalculationOutput> {
        // if (this.allCalculationsOutput !== undefined) {
        //     return _observableOf(this.allCalculationsOutput);
        // }
        return this._calculationService.getAllCalculations().pipe(_observableMergeMap((response_: any) => {
            this.allCalculationsOutput = response_;
            return _observableOf(this.allCalculationsOutput);
        }));
    }

    public getAllCalculationsnew(): Observable<ListResultDtoOfCalculationOutput> {
        // if (this.allCalculationsOutput !== undefined) {
        //     return _observableOf(this.allCalculationsOutput);
        // }
        return this._calculationService.getAllCalculationsnew().pipe(_observableMergeMap((response_: any) => {
            this.allCalculationsOutput = response_;
            return _observableOf(this.allCalculationsOutput);
        }));
    }

    public createCalculation(calculationInput: CalculationInput) {
        return this._calculationService.createCalculation(calculationInput).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public editCalculation(calculationInput: CalculationInput) {
        return this._calculationService.editCalculation(calculationInput).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public editCalculationName(calculationInput: CalculationInput) {
        return this._calculationService.editCalculationName(calculationInput).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public storeMaxDemandXml(LoadDataXml: LoadData, calculationId: number) {
        return this._calculationService.storeMaxDemandXml(LoadDataXml, calculationId).pipe(_observableMergeMap((response_: any) => {
            this.loadData = undefined;
            return _observableOf(response_);
        }));
    } public storeAutoMaxDemandXml(LoadDataXml: LoadData, calculationId: number) {
        return this._calculationService.storeAutoMaxDemandXml(LoadDataXml, calculationId).pipe(_observableMergeMap((response_: any) => {
            this.loadData = undefined;
            return _observableOf(response_);
        }));
    }
    public deleteCalculation(calculationId: number, calculationType: number) {
        return this._calculationService.deleteCalculation(calculationId, calculationType).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public deleteCalculationList(calculationIdList: string[]) {
        return this._calculationService.deleteCalculationList(calculationIdList).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public undoDeleteCalculation(calculationIdList: string[]) {
        return this._calculationService.undoDeleteCalculation(calculationIdList).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public copyCalculation(calculationId: number, calculationType: number) {
        return this._calculationService.copyCalculation(calculationId, calculationType).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public copyCalculationList(calculationIdList: string[]) {
        return this._calculationService.copyCalculationList(calculationIdList).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public getLoadGroupByInstallId(calculationId: number, installId: number = 0): Observable<ListResultDtoOfLoadInput> {
        // if (this.cableproLoadInput !== undefined && this.calculationId === calculationId) {
        //     return _observableOf(this.cableproLoadInput);
        // }
        return this._calculationService.getLoadGroupByInstallId(calculationId, installId).pipe(_observableMergeMap((response_: any) => {
            this.cableproLoadInput = response_;
            this.calculationId = calculationId;
            return _observableOf(this.cableproLoadInput);
        }));
    }
    public getSubGroupByLoadId(loadId: number): Observable<ListResultDtoOfSubGroupInput> {
        if (this.cableproSubGroupInputById !== undefined && this.loadId === loadId) {
            return _observableOf(this.cableproSubGroupInputById);
        }
        return this._calculationService.getSubGroupByLoadId(loadId).pipe(_observableMergeMap((response_: any) => {
            this.cableproSubGroupInputById = response_;
            this.loadId = loadId;
            return _observableOf(this.cableproSubGroupInputById);
        }));
    }
    public getAllSubGroup(): Observable<ListResultDtoOfSubGroupInput> {
        if (this.cableproSubGroupInputAll !== undefined) {
            return _observableOf(this.cableproSubGroupInputAll);
        }
        return this._calculationService.getAllSubGroup().pipe(_observableMergeMap((response_: any) => {
            this.cableproSubGroupInputAll = response_;
            return _observableOf(this.cableproSubGroupInputAll);
        }));
    }
    public getAllAutoMaxSubGroup(): Observable<ListResultDtoOfSubGroupInput> {
        if (this.cableproSubGroupInputAll !== undefined) {
            return _observableOf(this.cableproSubGroupInputAll);
        }
        return this._calculationService.getAllAutoMaxSubGroup().pipe(_observableMergeMap((response_: any) => {
            this.cableproSubGroupInputAll = response_;
            return _observableOf(this.cableproSubGroupInputAll);
        }));
    }
    public getMaxDemandByCalculationId(calculationId: number): Observable<LoadData> {
        /*if (this.loadData !== undefined && this.calculationId === calculationId) {
            return _observableOf(this.loadData);
        }*/
        return this._calculationService.getMaxDemandByCalculationId(calculationId).pipe(_observableMergeMap((response_: any) => {
            this.loadData = response_;

            return _observableOf(this.loadData);
        }));
    }
    public getAutoMaxDemandByCalculationId(calculationId: number): Observable<LoadData> {
        /*if (this.loadData !== undefined && this.calculationId === calculationId) {
            return _observableOf(this.loadData);
        }*/
        return this._calculationService.getAutoMaxDemandByCalculationId(calculationId).pipe(_observableMergeMap((response_: any) => {
            this.loadData = response_;

            return _observableOf(this.loadData);
        }));
    }
    public updateVoltageStatus(id: number, voltageStatus: boolean) {
        return this._calculationService.updateVoltageStatus(id, voltageStatus).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public refreshCurrentCalculations(): Observable<ListResultDtoOfCalculationOutput> {
        this.allCalculationsOutput = undefined;
        return _observableOf(this.allCalculationsOutput);
    }
    public setVoltageDrop(voltageSupplyLabel: string): Observable<string> {
        this.voltageDrop = voltageSupplyLabel;
        return _observableOf(this.voltageDrop);
    }
    public getVoltageDrop(): Observable<string> {
        return _observableOf(this.voltageDrop);
    }
    public setCalculationHeaderLabel(calcHeaderLabel: string): Observable<string> {
        this.calculationHeaderLabel = calcHeaderLabel;
        return _observableOf(this.calculationHeaderLabel);
    }
    public getCalculationHeaderLabel(): Observable<string> {
        return _observableOf(this.calculationHeaderLabel);
    }
    public setPrevValue(xcurrentValue: string): Observable<string> {
        this.currentValue = xcurrentValue;
        return _observableOf(this.currentValue);
    }
    public getPrevValue(): Observable<string> {
        return _observableOf(this.currentValue);
    }
    public getCalculationById(calculationId: number): Observable<ListResultDtoOfCalculationOutput> {
        //if (this.calculationOutput !== undefined && this.calculationId === calculationId) {
        //    return _observableOf(this.calculationOutput);
        //}
        return this._calculationService.getCalculationById(calculationId).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = response_;
            //this.calculationId = calculationId;
            return _observableOf(this.calculationOutput);
        }));
    }
    public storeWizardFactor(id: number | null | undefined, step: number | null | undefined, installationType: number | null | undefined, bunchingType: number | null | undefined, wizardFactor: string | null | undefined) {
        return this._calculationService.storeWizardFactor(id, step, installationType, bunchingType, wizardFactor).pipe(_observableMergeMap((response_: any) => {
            this.calculationOutput = undefined;
            this.allCalculationsOutput = undefined;
            return _observableOf(response_);
        }));
    }
    public setcableScheduleExportData(exportData: any): Observable<any> {
        if (exportData === undefined) {
            this.cableScheduleExportData = [];
        }
        else {
            this.cableScheduleExportData.push(exportData);
        }
        return _observableOf(this.cableScheduleExportData);
    }
}

@Injectable()
export class DRFService {
    private table23InstallationValues: Table23InstallationModel[];
    private table24InstallationValues: Table23InstallationModel[];

    constructor(private _drfService: DRFServiceServiceProxy) {

    }

    public getTable23Installations(standard?: string): Observable<any[]> {
        // if (this.table23InstallationValues !== undefined) {
        //     return _observableOf(this.table23InstallationValues);
        // }
        return this._drfService.getTable23Installations(standard).pipe(_observableMergeMap((response_: any) => {
            this.table23InstallationValues = response_;
            return _observableOf(this.table23InstallationValues);
        }));
    }

    public getTable24Installations(standard?: string): Observable<any[]> {
        // if (this.table24InstallationValues !== undefined) {
        //     return _observableOf(this.table24InstallationValues);
        // }
        return this._drfService.getTable24Installations(standard).pipe(_observableMergeMap((response_: any) => {
            this.table24InstallationValues = response_;
            return _observableOf(this.table24InstallationValues);
        }));
    }
}

@Injectable()
export class BSDRFService {
    private table23InstallationValues: Table23InstallationModel[];
    private table24InstallationValues: Table23InstallationModel[];

    constructor(private _bsDrfService: BSDRFServiceServiceProxy) {

    }

    public getTable23Installations(): Observable<Table23InstallationModel[]> {
        if (this.table23InstallationValues !== undefined) {
            return _observableOf(this.table23InstallationValues);
        }
        return this._bsDrfService.getTable23Installations().pipe(_observableMergeMap((response_: any) => {
            this.table23InstallationValues = response_;
            return _observableOf(this.table23InstallationValues);
        }));
    }

    public getTable24Installations(): Observable<Table23InstallationModel[]> {
        if (this.table24InstallationValues !== undefined) {
            return _observableOf(this.table24InstallationValues);
        }
        return this._bsDrfService.getTable24Installations().pipe(_observableMergeMap((response_: any) => {
            this.table24InstallationValues = response_;
            return _observableOf(this.table24InstallationValues);
        }));
    }
}

@Injectable()
export class BSService {
    private cableproOutput: any;
    private mode: any;
    private value: any;
    private insulationId: any;
    private conductorId: any;
    private armourId: any;
    private coreId: any;
    private phaseId: any;
    private methodIndex: any;
    private installTableId: any;
    private windSpeedId: any;
    private load: any;
    private protection: any;
    private calculationId: any;
    private conductorIndex: any;
    private coreIndex: any;

    constructor(private _BSService: BSServiceProxy) {

    }

    public getCableproes(mode: string | null | undefined, value: string | null | undefined, insulationId: number | null | undefined,
        conductorId: number | null | undefined, armourId: number | null | undefined, coreId: number | null | undefined, phaseId: number | null | undefined,
        methodIndex: number | null | undefined, installTableId: number | null | undefined, windSpeedId: number | null | undefined, load: string | null | undefined,
        protection: string | null | undefined, calculationId: number | null | undefined, conductorIndex: number | null | undefined, coreIndex: number | null | undefined): Observable<CableproOutput> {
        //if (this.cableproOutput !== undefined
        //    && this.mode === mode && this.value === value && this.insulationId === insulationId && this.conductorId === conductorId
        //    && this.armourId === armourId && this.coreId === coreId && this.phaseId === phaseId && this.methodIndex === methodIndex
        //    && this.installTableId === installTableId && this.windSpeedId === windSpeedId && this.load === load && this.protection === protection
        //    && this.calculationId === calculationId && this.conductorIndex === conductorIndex && this.coreIndex === coreIndex) {

        //    return _observableOf(this.cableproOutput);
        //} (mode, value, insulationId, conductorId, armourId, coreId, phaseId, methodIndex, installTableId, windSpeedId, load, protection, calculationId, ConductorIndex, CoreIndex)
        return this._BSService.getCableproes(mode, value, insulationId, conductorId, armourId, coreId, phaseId, methodIndex, installTableId, windSpeedId, load, protection, calculationId, conductorIndex, coreIndex).pipe(_observableMergeMap((response_: any) => {
            this.cableproOutput = response_;
            this.mode = mode;
            this.value = value;
            this.insulationId = insulationId;
            this.conductorId = conductorId;
            this.armourId = armourId;
            this.coreId = coreId;
            this.phaseId = phaseId;
            this.methodIndex = methodIndex;
            this.installTableId = installTableId;
            this.windSpeedId = windSpeedId;
            this.load = load;
            this.protection = protection;
            this.calculationId = calculationId;
            this.conductorIndex = conductorIndex;
            this.coreIndex = coreIndex;
            return _observableOf(this.cableproOutput);
        }));
    }

    public storeXml(cableproXml: CableproXmlInput) {
        return this._BSService.storeXml(cableproXml).pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }
}

@Injectable()
export class PlanService {
    private planOutput: any;
    private calcStandardId: number;
    private checkoutSessionId: string;
    private upgradePlanOutput: any;
    private selectedplanOutput: any;
    private checkOutSuccessOutput: any;
    private upgradePlanresult: string;
    private invoiceOutput: any;
    private cancelPlanResult: string;
    private cancelInvoiceResult: string;
    private couponOutput: any;

    constructor(private _planservice: SubscriptionServiceProxy) {

    }
    public getPlans(standardId: number): Observable<ListResultDtoOfPlanOutput> {
        // if (this.planOutput !== undefined && this.calcStandardId === standardId) {
        //     return _observableOf(this.planOutput);
        // }
        return this._planservice.getPlans(standardId).pipe(_observableMergeMap((response_: any) => {
            this.planOutput = response_;
            this.calcStandardId = standardId;
            return _observableOf(this.planOutput);
        }));
    }
    public getCoupons(standardId: number): Observable<ListResultDtoOfCouponOutput> {
        if (this.couponOutput !== undefined && this.calcStandardId === standardId) {
            return _observableOf(this.couponOutput);
        }
        return this._planservice.getCoupons(standardId).pipe(_observableMergeMap((response_: any) => {
            this.couponOutput = response_;
            this.calcStandardId = standardId;
            return _observableOf(this.couponOutput);
        }));
    }
    public getUpgradePlans(standardId: number): Observable<ListResultDtoOfPlanOutput> {
        if (this.upgradePlanOutput !== undefined) {
            return _observableOf(this.upgradePlanOutput);
        }
        return this._planservice.getUpgradePlans(standardId).pipe(_observableMergeMap((response_: any) => {
            this.upgradePlanOutput = response_;
            this.calcStandardId = standardId;
            return _observableOf(this.upgradePlanOutput);
        }));
    }
    public upgradePlan(planId: number, prorationDate: string): Observable<string> {
        return this._planservice.upgradePlan(planId, prorationDate).pipe(_observableMergeMap((response_: any) => {
            this.upgradePlanOutput = undefined;
            this.upgradePlanresult = response_;
            return _observableOf(this.upgradePlanresult);
        }));
    }
    // public createInvoice(planId: number): Observable<ListResultDtoOfInvoiceOutput> {
    //     return this._planservice.generateInvoice(planId).pipe(_observableMergeMap((response_: any) => {
    //         this.invoiceOutput = response_;
    //         return _observableOf(this.invoiceOutput);
    //     }));
    // }
    public previewInvoice(planId: number): Observable<ListResultDtoOfInvoiceOutput> {
        return this._planservice.previewInvoice(planId).pipe(_observableMergeMap((response_: any) => {
            this.invoiceOutput = response_;
            return _observableOf(this.invoiceOutput);
        }));
    }
    public deleteInvoice(invoiceId: string): Observable<string> {
        return this._planservice.deleteInvoice(invoiceId).pipe(_observableMergeMap((response_: any) => {
            this.cancelPlanResult = response_;
            return _observableOf(this.cancelPlanResult);
        }));
    }
    public cancelPlan(invoiceId: string): Observable<string> {
        return this._planservice.cancelPlan(invoiceId).pipe(_observableMergeMap((response_: any) => {
            this.cancelPlanResult = response_;
            return _observableOf(this.cancelPlanResult);
        }));
    }
    public cancelInvoice(invoiceId: string): Observable<string> {
        return this._planservice.cancelInvoice(invoiceId).pipe(_observableMergeMap((response_: any) => {
            this.cancelInvoiceResult = response_;
            return _observableOf(this.cancelInvoiceResult);
        }));
    }
    public createPlan(planid: number): Observable<string> {
        return this._planservice.subscribePlan(planid).pipe(_observableMergeMap((response_: any) => {
            this.checkoutSessionId = response_;
            return _observableOf(this.checkoutSessionId);
        }));
    }
    public resumePlan(planid: number): Observable<string> {
        return this._planservice.resumePlan(planid).pipe(_observableMergeMap((response_: any) => {
            this.checkoutSessionId = response_;
            return _observableOf(this.checkoutSessionId);
        }));
    }
    public getPlanById(): Observable<ListResultDtoOfPlanOutput> {
        return this._planservice.getPlanById().pipe(_observableMergeMap((response_: any) => {
            this.selectedplanOutput = response_;
            return _observableOf(this.selectedplanOutput);
        }));
    }
    public createSubscription(): Observable<CheckOutSuccessOutput> {
        return this._planservice.createSubscription().pipe(_observableMergeMap((response_: any) => {
            this.checkOutSuccessOutput = response_;
            return _observableOf(this.checkOutSuccessOutput);
        }));
    }

    public extendTrialPeriod(): Observable<boolean> {
        return this._planservice.extendTrialPeriod().pipe(_observableMergeMap((response_: any) => {
            return _observableOf(response_);
        }));
    }
}

@Injectable()
export class VersionService {
    private versionOutput: any;
    constructor(private _versionservice: VersionServiceProxy) {
    }
    public getCurrentVersion(): Observable<ListResultDtoOfVersionOutput> {
        if (this.versionOutput !== undefined) {
            return _observableOf(this.versionOutput);
        }
        return this._versionservice.getCurrentVersion().pipe(_observableMergeMap((response_: any) => {
            this.versionOutput = response_;
            return _observableOf(this.versionOutput);
        }));
    }
    public updateCurrentVersion(versionId: number): Observable<ListResultDtoOfVersionOutput> {
        return this._versionservice.updateVersionPerUser(versionId).pipe(_observableMergeMap((response_: any) => {
            this.versionOutput = undefined;
            return _observableOf(this.versionOutput);
        }));
    }

}
