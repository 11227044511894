import * as moment from "moment";

export class MomentHelper {
    static IsSubscriptionDateExpired(expirationDate: moment.Moment): boolean {
       var hasPlanExpired=false;
        if (expirationDate != null) {
                // UTC to local date time
            let currentDateTime = moment(); // time according to local time zone
            const utcMoment = moment.utc(expirationDate); 
            const expiryDateTime = moment(utcMoment.local().toDate());
            // both are in local format
            hasPlanExpired = currentDateTime.startOf('day').isAfter(expiryDateTime.startOf('day'), 'day');
        }

        return hasPlanExpired;
    }

    static GetSubscriptionDueDays(expirationDate: moment.Moment): number {
        var planDueDays=0;
         if (expirationDate != null) {
                // UTC to local date time
            let currentDateTime = moment(); // time according to local time zone
            const utcMoment = moment.utc(expirationDate); 
            const expiryDateTime = moment(utcMoment.local().toDate());
            // both are in local format

            planDueDays = currentDateTime.startOf('day').diff(expiryDateTime.startOf('day'), 'day');
        }
 
         return planDueDays;
     }

}
