import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { environment } from 'environments/environment';
import { API_BASE_URL } from './service-proxies';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private baseUrl: string;
    constructor(injector: Injector, private http: HttpClient, private _authService: AppAuthService, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.baseUrl = baseUrl ? baseUrl : "";
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(
            (error: any, caught: Observable<HttpEvent<any>>) => {
                const isExternalCall = this.baseUrl && !request.url.startsWith(this.baseUrl);
                if (isExternalCall) {
                    return of();
                }

                if (error.status === HttpStatusCode.Forbidden) {
                    window.location.href = environment.WebFacingUrl;
                }
                else if (error.status === 401) {
                    this._authService.logout(true);
                    localStorage.setItem("showLogoutMsg", 'true');
                    return of(error);
                }
                else if (error.status === 0) {
                    if (UrlHelper.isMaintenanceUrl(location.href))
                        return of();
                    else if (UrlHelper.isApiStatusUrl(request.url)) {
                        const originalUrl = encodeURIComponent(location.href); // Encode the current URL
                        let url = `/maintenance?originalUrl=${originalUrl}`;
                        // const queryString = location.search;
                        // const params = new URLSearchParams(queryString);
                        // const iframeParam = params.get("iframe");
                        // if (iframeParam === "1") {
                        //     const separator = url.includes('?') ? '&' : '?'; // Check if query parameters already exist
                        //     url += separator + "iframe=1";
                        // }
                        window.location.href = url;
                    }
                    //return of(error);
                }
                throw error;
            }
        ));
    }
}

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const queryString = location.search; // Returns:'?q=123'

        // Further parsing:
        const params = new URLSearchParams(queryString);
        
        // Check for "iframe" in the current URL
        let iframeParam = params.get("iframe");

         // If not found, check inside "originalUrl"
         if (!iframeParam) {
            const originalUrlEncoded = params.get("originalUrl"); // Get the originalUrl
            if (originalUrlEncoded) {
                const originalUrl = decodeURIComponent(originalUrlEncoded); // Decode the originalUrl
                const originalParams = new URLSearchParams(originalUrl.split('?')[1]); // Extract query params
                iframeParam = originalParams.get("iframe"); // Look for iframe in originalUrl
            }
        }

        if (iframeParam == "1") {
            // Clone the request to add the new headers
            const clonedRequest = req.clone({
                setHeaders: {
                    'x-iframe-origin': environment.WebFacingUrl, // Replace with your custom header
                },
            });
            return next.handle(clonedRequest);
        }
        return next.handle(req);
    }
}